export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Version"])},
        "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
        "currentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current User"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed in loadinng"])}
      },
      "zh": {
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前版本"])},
        "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 首页"])},
        "currentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前登录用户"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退 出"])},
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载失败"])}
      }
    }
  })
}
